'use client';
import { useEffect, useState } from 'react';

const queries =
  typeof window !== 'undefined'
    ? {
        isDesktop: window?.matchMedia('(min-width: 1024px)'),
        isTablet: window.matchMedia('(max-width: 1023px) and (min-width: 768px)'),
        isMobile: window.matchMedia('(max-width: 767px)'),
      }
    : {
        isDesktop: null,
        isTablet: null,
        isMobile: null,
      };

const useRWD = () => {
  const [breakpoints, setBreakpoints] = useState({
    isDesktop: typeof window !== 'undefined' ? queries?.isDesktop?.matches : false,
    isTablet: typeof window !== 'undefined' ? queries?.isTablet?.matches : false,
    isMobile: typeof window !== 'undefined' ? queries?.isMobile?.matches : false,
  });

  useEffect(() => {
    const handlersMap = new Map();

    Object.entries(queries).forEach(([breakpoint, match]) => {
      const handler = (event?: MediaQueryListEvent) => {
        setBreakpoints((prev) => ({
          ...prev,
          [breakpoint]: event?.matches,
        }));
      };

      handlersMap.set(breakpoint, handler);

      match?.addEventListener('change', handler);
    });

    return () => {
      Object.entries(queries).forEach(([breakpoint, match]) => {
        const handler = handlersMap.get(breakpoint);

        if (!handler) {
          return;
        }

        match?.addEventListener('change', handler);
      });
    };
  }, []);

  return breakpoints;
};

export default useRWD;

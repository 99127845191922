import clsx from 'clsx';
import { PrismicNextImage } from '@prismicio/next';
import { ContentRelationshipItemLinkFieldExtended } from '@/prismicCustomTypes';
import Link from '@/components/atoms/Link';
const SubDropdownItem = ({
  items,
  grid,
  hideMenu
}: {
  items: ContentRelationshipItemLinkFieldExtended[];
  grid: boolean;
  hideMenu: () => void;
}) => {
  return <div className={clsx('mt-5 grid gap-x-8 gap-y-6', grid ? 'lg:grid-cols-2' : 'grid-cols-1')} data-sentry-component="SubDropdownItem" data-sentry-source-file="index.tsx">
      {items?.map(({
      data
    }, index) => {
      return <Link onClick={() => hideMenu()}
      // @ts-expect-error - link is not typed correctly
      href={data?.link?.url || ''} className="flex hover:opacity-50" key={index}>
            <PrismicNextImage className="mr-4 h-6 w-6" field={data?.icon} />
            <div>
              <h3 className="text-body-7 font-semibold text-gray-600">{data?.title}</h3>
              <p className="hidden text-h10 text-gray-500 lg:block">{data?.description}</p>
            </div>
          </Link>;
    })}
    </div>;
};
export default SubDropdownItem;
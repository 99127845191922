import { useEffect } from 'react';
import { scrollbarWidth } from '@xobotyi/scrollbar-width';
import useRWD from './useRWD';

const useScrollLock = (condition?: boolean) => {
  const { isDesktop } = useRWD();

  useEffect(() => {
    const { body } = document;
    const defaultPadding = isDesktop ? 12 : 0;
    const scrollBarWidthPx = `${(scrollbarWidth() as number) + defaultPadding || defaultPadding}px`;

    if (body) {
      if (condition) {
        body.style.overflow = 'hidden';
        body.style.paddingRight = scrollBarWidthPx;
        document
          .getElementById('nav')
          ?.style.setProperty('padding-right', (scrollbarWidth() as number) + 'px');
      } else {
        body.style.overflow = 'auto';
        body.style.paddingRight = defaultPadding + 'px';
        document.getElementById('nav')?.style.setProperty('padding-right', '0px');
      }
    }

    return () => {
      if (body) {
        body.style.overflow = 'auto';
      }
    };
  }, [condition, isDesktop]);
};

export default useScrollLock;

'use client';

import React, { PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';
const SWRProvider = ({
  children
}: PropsWithChildren) => {
  return <SWRConfig value={{
    fetcher: (resource, init) => fetch(resource, init).then(res => res.json())
  }} data-sentry-element="SWRConfig" data-sentry-component="SWRProvider" data-sentry-source-file="SWRProvider.tsx">
      {children}
    </SWRConfig>;
};
export default SWRProvider;
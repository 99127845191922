import { useEffect, useState } from 'react';

const TOP_OFFSET = 30;
const useScrollDetect = () => {
  const [scrollMove, setScrollMove] = useState(false);
  useEffect(() => {
    if (window.scrollY >= TOP_OFFSET) {
      setScrollMove(true);
    }
    const handleScroll = () => {
      if (window.scrollY >= TOP_OFFSET) {
        setScrollMove(true);
      } else {
        setScrollMove(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return scrollMove;
};

export default useScrollDetect;

import React from 'react';
import clsx from 'clsx';
import { PrismicNextLink } from '@prismicio/next';
import Link from '../Link';
import { LinkButtonProps } from './types';
const baseStyles = 'flex items-center justify-center font-medium py-3 px-3.5 text-button rounded-button transition duration-150 ease-in-out';
const getStyleByVariant = ({
  variant,
  disabled
}: {
  variant: LinkButtonProps['variant'];
  disabled?: boolean;
}) => {
  switch (variant) {
    case 'primary':
      return clsx({
        'bg-gray-200 text-gray-400 cursor-default': disabled,
        'bg-primary-600 hover:bg-primary-700 text-gray-600': !disabled
      });
    case 'secondary':
      return 'bg-secondary-600 hover:bg-secondary-400 border text-white';
    case 'outline':
      return 'text-gray-600 bg-transparent border border-gray-600 hover:bg-gray-100';
    case 'error-outline':
      return 'text-error-300 bg-transparent border border-error-300 hover:bg-error-100';
    case 'light-outline':
      return 'border-gray-200 bg-transparent hover:bg-gray-100';
    case 'ghost':
      return 'text-gray-600 bg-transparent hover:opacity-50';
    default:
      return '';
  }
};
const LinkButton: React.FC<LinkButtonProps> = ({
  variant = 'primary',
  children,
  className = '',
  field,
  href,
  disabled,
  ...props
}) => {
  const styles = clsx(baseStyles, getStyleByVariant({
    variant,
    disabled
  }), disabled ? 'pointer-events-none' : '', className);
  return field ? <PrismicNextLink field={!disabled ? field : null} className={styles} aria-label="prismic link" {...props}>
      {children}
    </PrismicNextLink> : <Link href={href || '/'} className={styles} {...props} aria-disabled={disabled} tabIndex={disabled ? -1 : undefined}>
      {children}
    </Link>;
};
export default LinkButton;
'use client';

import React from 'react';
import NextLink from 'next/link';
import { useLang } from '@/hooks/useLang';
import { LinkProps } from './types';
import { resolveHref } from './utils';
const Link: React.FC<LinkProps> = ({
  disabled,
  href,
  children,
  locale,
  ...rest
}) => {
  const lang = useLang();
  return <NextLink href={resolveHref(href, locale, lang)} aria-disabled={disabled || rest['aria-disabled']} tabIndex={disabled ? -1 : rest.tabIndex} {...rest} data-sentry-element="NextLink" data-sentry-component="Link" data-sentry-source-file="index.tsx">
      {children}
    </NextLink>;
};
export default Link;